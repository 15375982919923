<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="userData === undefined">
            <h4 class="alert-heading">Error fetching person data</h4>
            <div class="alert-body">
                No person found with this person id. Check
                <b-link class="alert-link" :to="{ name: 'User' }">
                    User List
                </b-link>
                for other User.
            </div>
        </b-alert>

        <div v-if="loading" class="text-center">
            <b-spinner variant="primary" class="" />
        </div>

        <div v-else-if="userData">
            <!-- User Info: Input Fields -->
            <formulateForm v-model="formData" name="userForm">
                <b-card>
                    <!-- Media -->
                    <b-media class="mb-2">
                        <template #aside>
                            <b-avatar
                                ref="previewEl"
                                :text="getNameInitials"
                                variant="info"
                                size="90px"
                                rounded
                            />
                        </template>
                        <h4 class="mb-1">
                            {{ userData.name }}
                        </h4>
                    </b-media>
                    <b-row>
                        <!-- Field: Name -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                label="Name"
                                name="name"
                                placeholder="Name"
                                type="text"
                                validation="optional|max:200,length"
                                disabled
                            />
                        </b-col>

                        <!-- Field: Status -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                label="Status"
                                name="status"
                                placeholder="Select a status"
                                class="disabled"
                                type="vue-select"
                                validation="optional|max:32,length"
                                :options="statusOptions"
                                disabled
                                @update:input="formData.status = $event"
                            />
                        </b-col>

                        <!-- Field: Password -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                label="Password"
                                name="password"
                                placeholder="Password"
                                type="password"
                                validation="optional|max:255,length"
                                disabled
                            />
                        </b-col>

                        <!-- Field: Email -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                label="Email"
                                name="email"
                                placeholder="E-mail address"
                                type="text"
                                validation="optional|email"
                                disabled
                            />
                        </b-col>

                        <!-- Field: Role -->
                        <b-col cols="12" md="4">
                            <formulateInput
                                label="Roles"
                                name="roles"
                                class="disabled"
                                :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                placeholder="Select a role"
                                type="vue-select"
                                validation="optional|max:255,length"
                                :options="rolesOptions"
                                multiple
                                disabled
                                @update:input="formData.roles = $event"
                            />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <formulateErrors />
                        </b-col>
                    </b-row>
                </b-card>
            </formulateForm>
        </div>
    </div>
</template>

<script>
import {
    BAlert,
    BAvatar,
    BCard,
    BCol,
    BLink,
    BMedia,
    BRow,
    BSpinner,
} from "bootstrap-vue"
import router from "@/router"
import Api from "@/services/api"
import _ from "lodash"

export default {
    components: {
        BAlert,
        BLink,
        BSpinner,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BCard,
    },
    data() {
        return {
            formData: {
                name: "",
                status: "",
                email: "",
                password: "",
                roles: "",
            },

            rolesOptions: [],
            statusOptions: [],
            updatedUserData: null,
            userData: null,
            loading: true,
        }
    },

    computed: {
        getNameInitials() {
            const split = this.userData.name.split(" ", 2)
            if (split.length < 2) {
                return this.userData.name[0] + this.userData.name[1]
            }

            const first = split[0]
            const second = split[1]

            return first.charAt(0) + second.charAt(0)
        },
    },

    mounted() {
        this.getStatus()
        this.getRoles()
        this.getUserById(router.currentRoute.params.id)
    },

    methods: {
        loadData() {
            // Setting up initial values
            this.updatedUserData = this.userData
            this.formData.name = this.userData.name
            this.formData.email = this.userData.email
            this.formData.password = this.userData.password
            this.formData.status =
                (_.get(this.userData, "status.id") &&
                    this.userData.status.id.toString()) ||
                null
            this.formData.roles =
                (_.get(this.userData, "roles") &&
                    this.userData.roles.map(el => el.id.toString())) ||
                []
            this.loading = false
        },
        async getUserById(id) {
            const response = await Api.find("users", id, {
                include: "roles,status",

                fields: {
                    roles: "name",
                    status: "description",
                },
            })

            this.userData = response.data
            this.loadData()
        },

        async getRoles() {
            const response = await Api.findAll("roles", {
                fields: { roles: "name" },
            })

            this.rolesOptions = response.data.map(el => ({
                value: el.id,
                label: el.name,
            }))
        },

        async getStatus() {
            const response = await Api.findAll("status", {
                fields: { status: "description" },
            })

            this.statusOptions = response.data.map(el => ({
                value: el.id,
                label: el.description,
            }))
        },
    },
}
</script>
